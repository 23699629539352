import React from "react";
import AppWithRainbow from "./AppWithRainbow";
import "@rainbow-me/rainbowkit/styles.css";

import {
  apiProvider,
  configureChains,
  getDefaultWallets,
  RainbowKitProvider,
} from "@rainbow-me/rainbowkit";
import { chain, createClient, WagmiProvider } from "wagmi";

export default function App() {
  const { chains, provider } = configureChains(
    [
      chain.mainnet,
      chain.polygon,
      chain.polygonMumbai,
      chain.optimism,
      chain.arbitrum,
    ],
    [
      apiProvider.alchemy(process.env.REACT_APP_ALCHEMY_ID),
      apiProvider.fallback(),
    ]
  );

  const { connectors } = getDefaultWallets({
    appName: "My RainbowKit App",
    chains,
  });

  const wagmiClient = createClient({
    autoConnect: true,
    connectors,
    provider,
  });

  return (
    <WagmiProvider client={wagmiClient}>
      <RainbowKitProvider chains={chains}>
        <AppWithRainbow />
      </RainbowKitProvider>
    </WagmiProvider>
  );
}