import axios from 'axios';
const serverURL = 'https://bm5xeikcth.execute-api.us-west-2.amazonaws.com/dev';

export async function logEvent(userID, eventType, event, severity, link, extra) {
	if(userID != null) {
		userID = userID.toLowerCase();
	}
	await axios
		.post(serverURL + '/log_event', {
			event_type: eventType,
			user_id: userID,
			event,
			severity,
			url: link,
			extra,
		});
}