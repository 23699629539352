import * as encryptUtils from '../encryptDecryptUtils';

export async function getMintedSongsWithVotes(mintedSongs, address) {
	if(address != null) {
		address = address.toLowerCase();
	}
	if(mintedSongs == null) {
		return [];
	}
	mintedSongs = await encryptUtils.getLeaderboardDataBulk(mintedSongs, address, "uri");
	return mintedSongs;
}

export async function getSoundNFTs(address) {
	if(address != null) {
		address = address.toLowerCase();
	}
	let rawSounds = require('../SoundData.json');
	let soundOwners = {};
	let sounds = [];
	for(let i = 0; i < rawSounds.length; i++) {
		let song = rawSounds[i];
		if(song.name == null) {
			continue;
		}
		let songName = song.name.split("#")[0];
		if(songName in soundOwners) {
			if(song.owner != null) {
				soundOwners[songName].push(song.owner);
			}
		} else {
			sounds.push(song);
			if(song.owner != null) {
				soundOwners[songName] = [song.owner];
			}
		}
	}
	sounds = await encryptUtils.getLeaderboardDataBulk(sounds, address, "animation_original_url");
	sounds = sounds.map(song => {
		if(song.name == null || song.animation_original_url == null) {
			return null;
		}
		let animationURLSplit = song.animation_original_url.split("/");
		let tokenCID = animationURLSplit[animationURLSplit.length - 1];
		return {
			tokenId: null,
			tokenCID,
			image: song.image_url,
			name: song.name.split("#")[0],
			artist: song.artist,
			uri: null,
			url: song.animation_original_url,
			type: "free",
			owner: null,
			totalUpvotes: song.totalUpvotes,
			userVote: song.userVote,
			platform: "Sound",
		}
	}).filter(x => x);
	return {
		'sounds': sounds,
		'soundOwners': soundOwners,
	}
}

export async function getCatalogNFTs(address) {
	if(address != null) {
		address = address.toLowerCase();
	}
	let catalog = require('../CatalogData.json');
	catalog = catalog.filter(song => song.animation_url == null || !song.animation_url.includes(".aif"));
	catalog = await encryptUtils.getLeaderboardDataBulk(catalog, address, "animation_original_url");
	catalog = catalog.map(song => {
		let animationURLSplit = song.animation_original_url.split("/");
		let tokenCID = animationURLSplit[animationURLSplit.length - 1];
		let url = "https://the402open.mypinata.cloud/ipfs/" + tokenCID;
		return {
			tokenId: null,
			tokenCID,
			image: song.image_url,
			name: song.name.split("-").slice(1).join("-"),
			artist: song.name.split("-")[0],
			uri: null,
			url,
			type: "free",
			owner: song.owner,
			totalUpvotes: song.totalUpvotes,
			userVote: song.userVote,
			platform: "Catalog",
		}
	});
	return catalog;
}

export async function getGlassNFTs(address) {
	if(address != null) {
		address = address.toLowerCase();
	}
	let rawGlassNFTs = require('../GlassData.json');
	let glassOwners = {};
	let glass = [];
	for(let i = 0; i < rawGlassNFTs.length; i++) {
		let glassNFT = rawGlassNFTs[i];
		if(glassNFT.name == null) {
			continue;
		}
		let glassName = glassNFT.name;
		if(glassName in glassOwners) {
			if(glassNFT.owner != null) {
				glassOwners[glassName].push(glassNFT.owner);
			}
		} else {
			glass.push(glassNFT);
			if(glassNFT.owner != null) {
				glassOwners[glassName] = [glassNFT.owner];
			}
		}
	}
	glass = await Promise.all(glass.map(async (glassNFT) => {
		let animationURLSplit = glassNFT.animation_original_url.split("/");
		let tokenCID = animationURLSplit[animationURLSplit.length - 1];
		let totalUpvotes = 0;
		let userVote = false;
		let leaderboardData = await encryptUtils.getLeaderboardData(tokenCID, address);
		totalUpvotes = leaderboardData["totalUpvotes"];
		userVote = leaderboardData["userVote"];
		glassNFT.totalUpvotes = totalUpvotes;
		glassNFT.userVote = userVote;
		return glassNFT;
	}));
	glass = glass.map(glassNFT => {
		if(glassNFT.name == null) {
			return null;
		}
		let animationURLSplit = glassNFT.animation_original_url.split("/");
		let tokenCID = animationURLSplit[animationURLSplit.length - 1];
		let url = "https://the402open.mypinata.cloud/ipfs/" + tokenCID;
		return {
			tokenId: null,
			tokenCID,
			image: glassNFT.image_url,
			name: glassNFT.name,
			artist: glassNFT.artist,
			uri: null,
			url,
			type: "free",
			owner: null,
			totalUpvotes: glassNFT.totalUpvotes,
			userVote: glassNFT.userVote,
			platform: "Glass",
		}
	}).filter(x => x);
	return {
		'glass': glass,
		'glassOwners': glassOwners,
	}
}